<template>
  <div class="viewport">
    <ErrorPage v-if="$store.state.error" />
    <LoadingPage v-else-if="$store.getters.appLoading" />
    <ThankYouPage v-else-if="$store.state.opportunity.contractSigned" />
    <LandingPage v-else />

    <Modal v-show="$store.state.modal.show" @close="$store.dispatch('hideModal')" :title="$store.state.modal.title" >
      <ModalErrorMessageSubview v-if="$store.state.modal.subview == 'error'" :message="$store.state.modalErrorMessageSubview.message" />
      <ModalIncompleteItemsSubview v-else-if="$store.state.modal.subview == 'incompleteItems'" :messages="$store.state.modalIncompleteItemsSubview.messages" />
      <ModalSignatureSubview v-else-if="$store.state.modal.subview == 'signature'" @cancelled="$store.dispatch('hideModal')" @accepted="updateSignature" />
    </Modal>

    <LoadingSplash scope="fixed" v-if="$store.state.showLoadingSplash" />
  </div>
</template>

<script>
import LoadingPage from '@/views/LoadingPage.vue'
import ErrorPage from '@/views/ErrorPage.vue'
import LandingPage from '@/views/LandingPage.vue'
import ThankYouPage from '@/views/ThankYouPage.vue'
import Modal from '@/components/reusable/Modal.vue'
import LoadingSplash from '@/components/reusable/LoadingSplash.vue'

import ModalErrorMessageSubview from "@/components/reusable/ModalErrorMessageSubview.vue"
import ModalIncompleteItemsSubview from "@/components/reusable/ModalIncompleteItemsSubview.vue"
import ModalSignatureSubview from "@/components/reusable/ModalSignatureSubview.vue"


export default {
  components:{
    LandingPage,
    LoadingPage,
    ErrorPage,
    ThankYouPage,
    Modal,
    LoadingSplash,
    ModalErrorMessageSubview,
    ModalIncompleteItemsSubview,
    ModalSignatureSubview
  },
  computed: {

  },
  methods: {
    updateSignature(data) {
      this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-DocumentSigned" });
      this.$store.dispatch('updateSignature', data);
      this.$store.dispatch('hideModal');
    }
  },
  created() {
    this.$store.dispatch("loadInitialState");

    // When we finish loading the initial app state, identify FS
    this.unwatch = this.$store.watch(
      (state, getters) => getters.appLoading,
      (newValue, oldValue, state) => {
        if (newValue === false) {

          this.$FullStory.identify(this.$store.state.opportunity.clientId, {
            displayName: this.$store.state.opportunity.dealerInformation.dealerName,
            dealership: this.$store.state.opportunity.dealerInformation.dealerName,
            dealershipGroup: this.$store.state.opportunity.dealerInformation.dealerGroup
          });

        }
      },
    );
  },
  beforeUnmount() {
    this.unwatch();
  },
}
</script>


<style lang="scss">
body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
}

button {
  cursor: pointer;
}

img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.viewport {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  background-color: #F5F5F6;
}

.outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  background: white;
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.animated {
  position: relative;
}

.animated::after {
  content: "";
  position: absolute;
  --angle: 0deg;
  border: none;
  background: conic-gradient(from var(--angle), #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82, #f79533);
  border-radius: 15px;
  animation: 1s rotate linear infinite !important;
  padding: 10px;
  box-sizing: content-box;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}


// .animated {
//     background-image:
//     linear-gradient(white, white),
//     linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
//     border-radius: 15px;;
//     background-repeat: no-repeat;
//     background-size: 100% 100%, 100% 200%;
//     background-position: 0 0, 0 0;
//     background-origin: padding-box, border-box;
//     animation: highlight 0.5s infinite alternate;
// }

// @keyframes highlight {
//     0% {
//     background-position: 0 0, 0 0%;
//     }
//     50% {
//     background-position: 0 0, 0 50%;
//     }
//     100% {
//     background-position: 0 0, 0 100%;
//     }
// }

@media (max-width: 600px) {
  
}

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid rgb(255, 255, 255);
    background: rgba(0,0,0,0.0);
    border-radius: .2em;
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
    -webkit-transition: all .275s;
        transition: all .275s;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '✕';
    position: absolute;
    top: .525em;
    left: .18em;
    font-size: 1.375em;
    color: #FFF;
    line-height: 0;
    -webkit-transition: all .2s;
        transition: all .2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  [type="checkbox"]:disabled:checked + label:after {
    color: #777;
  }

  [type="checkbox"]:disabled + label {
    color: #aaa;
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(78, 78, 78, 0.2);
  }
</style>
