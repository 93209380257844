<template>
  <div class="package-section-card-root" :style="{ '--border-color': cardPackage.borderColor, '--accent-color': cardPackage.accentColor, '--display-pseudo': isCurrentPackage ? 'flex' : 'none' }" :class="[$store.state.selectedPackage === cardPackage.id ? 'selected' : '']" @click="packageSelected()">
      <div class="content">
          <div class="current-package-marker-mobile" v-if="isCurrentPackage" style="font-size: 12px;"><i class="fas fa-check" style="color: rgb(20, 192, 20);"></i> Current Package</div>

          <div class="name">{{ cardPackage.name }}</div>

          <div class="rate">{{ cardPackage.rate }}/month</div>

          <div class="accent" :style="{ '--accent-color': cardPackage.accentColor }">{{ cardPackage.accent }}</div>
        
          <!-- <span @click="isExpanded = !isExpanded" class="expand-button">Features Include <i class="fas fa-angle-down expand-carret" :class="[isExpanded ? 'expanded' : '']"></i></span> -->
          <span class="features-heading">{{ cardPackage.header }}</span>

          <div class="features">
              <div class="feature" v-for="(feature, index) in features" :key="index"><img src="@/assets/images/small-checkmark.svg" alt="" style="user-select: none;"> {{ feature }}</div>
          </div>

          <div class="link"><a :href="$store.state.links.sfe_package_site" target="_blank" @click.stop="packageDetailSelected()">Link to all package features</a></div>

        <div class="button-container">
            <img class="check-button" src="@/assets/images/checkmark.png" alt="checkmark" style="user-select: none;" v-if="$store.state.selectedPackage === cardPackage.id">
            <button :style="{ '--accent-color': cardPackage.accentColor, '--sub-accent-color': cardPackage.subAccentColor }" v-else>{{ buttonText }}</button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        cardPackage: { // Apparently package is a reserved name in Javascript and/or Node, so different nomenclature had to be used.
            type: Object,
            required: true
        }
    },
    data() {
        return { isExpanded: true }
    },
    computed: {
        features() {
            return this.isExpanded ? this.cardPackage.features : [this.cardPackage.features[0]];
        },
        buttonText() {
            if (this.$store.state.selectedPackage === this.cardPackage.id)
                return "SELECTED"
            else if(this.$store.getters.currentPackage.includes("SELL") && this.cardPackage.id == "1e696c9d-1fab-4245-9372-168fc4583eaf")
                return "RENEW";
            else if(this.$store.getters.currentPackage.includes("ENGAGE") && this.cardPackage.id == "53106d28-ed57-469a-a015-8778f208b5cd")
                return "RENEW";
            else if(this.$store.getters.currentPackage.includes("RESPOND") && this.cardPackage.id == "b86234ab-75be-4b23-8f71-8460e4743304")
                return "RENEW";
            else
                return "SELECT";
        },
        rate() {
            return this.$store.getters.currentPackage.rate;
            // // If the current package is the same as the card, don't display any price delta
            // if(this.isCurrentPackage)
            //     return "";
            
            // // Rate deltas in the package definitions are relative to the lowest package. If the dealer is currently on the lowest package, display all deltas as they are defined.
            // else if(this.$store.getters.currentPackage.includes("RESPOND"))
            //     return ` + $${this.cardPackage.rate}`;

            // // TODO: Create more sophisticated delta logic
            // else
            //     return ` + $300`;
        },
        isCurrentPackage() {
            var predicate = this.cardPackage.id == "1e696c9d-1fab-4245-9372-168fc4583eaf"
            ? "SELL" : this.cardPackage.id == "53106d28-ed57-469a-a015-8778f208b5cd"
                ? "ENGAGE" : this.cardPackage.id == "b86234ab-75be-4b23-8f71-8460e4743304"
                    ? "RESPOND" : "UNKNOWN";


            return this.$store.getters.currentPackage.includes(predicate);
        }
    },
    methods: {
        packageSelected() {
            this.$store.dispatch("sendFullStoryEvent", { 
                FS: this.$FullStory,
                eventName: "SFE-PackageSelected",
                eventPayload: {
                    previousPackage: this.formattedPackageNameForId(this.$store.state.selectedPackage),
                    newPackage: this.formattedPackageNameForId(this.cardPackage.id)
                }
            });

            this.$store.commit('UPDATE_SELECTED_PACKAGE', this.cardPackage.id)
        },
        packageDetailSelected() {
            this.$store.dispatch("sendFullStoryEvent", { 
                FS: this.$FullStory,
                eventName: "SFE-PackageDetailClicked",
                eventPayload: {
                    package: this.formattedPackageNameForId(this.cardPackage.id)
                }
            });
        },
        formattedPackageNameForId(id) {
            return id == "1e696c9d-1fab-4245-9372-168fc4583eaf"
            ? "Sell" : id == "53106d28-ed57-469a-a015-8778f208b5cd"
                ? "Engage" : id == "b86234ab-75be-4b23-8f71-8460e4743304"
                    ? "Respond" : "";
        }
    }
}
</script>

<style lang="scss" scoped>
.package-section-card-root {    
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 15px;
    border: solid #E1E1E1 1px;
    width: 100%;
    max-width: 450px;
    transition: all 0.15s ease;
    cursor: pointer;
    border: 2px solid var(--border-color);
    
}

.content {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    padding: 24px;
    padding-bottom: 15px;
}

.selected {
    transform: scale(1.03);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.features-heading {
    font-weight: 500;
    margin-top: 10px;
    text-align: left;
}

.accent {
    color: var(--accent-color);
    font-weight: bold;
    user-select: none;
}

.name {
    font-size: 26px;
    font-weight: bold;
    color: #4D4D4D;
    user-select: none;
}

.rate {
    user-select: none;
    font-size: 18px;
    color: #292929;
}

.features {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    font-size: 12px;
    user-select: none;
    font-weight: 600;
}

.feature {
    display: flex;
    column-gap: 4px;
    align-items: center;
}

.link {
    display: flex;
    height: 100%;
    flex: 1;
    align-items: flex-end;
}

.expand-button {
    cursor: pointer;
}

.expand-carret {
    transition: transform 0.2s ease;
}

.expand-carret.expanded {
    transform: scaleY(-1);
}

.button-container {
    display: flex;
    justify-content: center;
}

.feature-checkmark {
    font-size: 16px;
    color: #4D4D4D;
}

.check-button {
    font-size: 55px;
    font-weight: bold;
    color: rgb(20, 192, 20);
}

a {
    font-weight: 600;
    font-size: 15px;
    color: var(--accent-color);
}

button {
    width: 50%;
    max-height: 50px;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    color: var(--accent-color);
    background: var(--sub-accent-color);
    border: 1px solid var(--accent-color);
    border-radius: 5px;
    margin-bottom: 20px;
}

@media (min-width: $media-min-width) {
    button {
        width: 100%;
    }

    .package-section-card-root {
        min-height: 595px;
        max-height: 800px;
    }

    .current-package-marker-mobile {
        display: none;
    }

    .content {
        height: 100%;
        flex: 1;
        box-sizing: border-box;
        justify-content: flex-start;
        align-items: center;
    }

    .accent {
        text-align: center;
    }

    .button-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        min-height: 100px;
    }

    .package-section-card-root::before {
        display: var(--display-pseudo);
        content: "Current Package";
        position: absolute;
        width: 100%;
        justify-content: center;
        top: -30px;
        font-weight: 500;
        color: white;
    }
}

@media (min-width: 768px) and (max-width: 844px) {
    .features-heading {
        font-size: 14px;
    }
    .features {
        font-size: 11px;
        row-gap: 10px;
    }

}
</style>