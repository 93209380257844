<template>
  <div class="step-2-section-root" id="step-2-scroll-anchor">
      <div class="header">
          <div class="header-sub-left">
                  <div class="label">Step Two:</div>
                  <div class="sub-label">As a GM Dealer you are eligible for up to 3 additional tech gifts when you enhance your pre-order and lead response process with the industry’s top lead response and customer communication technology!</div>
                  <div class="flavor-text">Digital Air Strike’s patented Lead Response Technology enables you to consistently follow up with all leads that come in after hours, so you never miss a sale.</div>
                  <div class="disclaimer-text">* View full package details <a href="https://dasgm2023.com/lead-response-pre-order-engagement-solutions/" target="_blank" style="text-decoration: none;">here</a>. Discounts applicable during 2023 SMRM Enrollment period.</div>
              </div>
            <div class="header-sub-right">2</div>
      </div>

      <div class="cards">
          <AddonCard :addon="$store.state.addons.best" />
          <AddonCard :addon="$store.state.addons.better" />
          <AddonCard :addon="$store.state.addons.good" />
      </div>
  </div>
</template>

<script>
import AddonCard from '@/components/AddonCard.vue'

export default {
  components: {
    AddonCard
  }
}
</script>

<style lang="scss" scoped>
.step-2-section-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
}

.header {
    display: flex;
    margin-top: 20px;
}

.header-sub-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    row-gap: 10px;
}

.header-sub-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    color: white;
    text-align: right;
    font-size: 150px;
    opacity: 0.5;
    font-weight: bold;
}

.label {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.sub-label {
    color: #FFF;
    font-weight: bold;
}

.flavor-text {
    color: white;
    font-size: 12px;
}

.disclaimer-text {
    color: white;
    font-size: 10px;
}

.cards {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    align-items: center;
    row-gap: 30px;
    margin-top: 25px;
    padding-bottom: 50px;
}

@media (min-width: $media-min-width) {
    .step-2-section-root {
        margin-top: 50px;
    }

    .header {
        margin-top: 0px;
        width: 80%;
    } 

    .label {
        font-size: 42px
    }

    .sub-label {
        font-size: 18px;
    }

    .flavor-text { 
        font-size: 11px;
    }

    .disclaimer-text {
        font-size: 11px;
    }

    .header-sub-right {
        font-size: 200px;
    }

    .cards {
        margin-top: 15px;
    }
}
</style>