<template>
  <div class="landing-page-root">
    <div class="outer-container">
      <WelcomeSection />
      <HowItWorksSection />
      <StepsSection />
      <SubmitSection />
      <!-- <PackageSection /> -->
    </div>
  </div>
</template>

<script>
import WelcomeSection from '@/components/WelcomeSection.vue'
import HowItWorksSection from '@/components/HowItWorksSection.vue'
import StepsSection from '@/components/StepsSection.vue'
import SubmitSection from '@/components/SubmitSection.vue'

export default {
  name: 'LandingPage',
  components: {
    WelcomeSection,
    HowItWorksSection,
    StepsSection,
    SubmitSection
  },
  mounted() {
    this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-PageViewed"});
  }
}
</script>

<style lang="scss" scoped>
.landing-page-root {

}
</style>
