import { createStore, createLogger } from 'vuex'
import { addons, links, packages, strings } from './definitions.js';
import { isLocal, isLocalOrDev } from '../common.js';
import Axios from 'axios'

export default createStore({
  plugins: [isLocalOrDev() ? createLogger() : () => null],
  state: {
    packages: packages,
    links: links,
    addons: addons,
    strings: strings,
    sub: "",
    error: null,
    opportunity: null,
    opportunityOriginal: null,
    loaded: { opportunity: false },
    modal: { show: false, title: "", subview: "" },
    modalIncompleteItemsSubview: { messages: [] },
    modalErrorMessageSubview: { message: "" },
    modalSignatureSubview: {  },
    showLoadingSplash: false,
    selectedPackage: "1e696c9d-1fab-4245-9372-168fc4583eaf",
    selectedAddon: "Best",
    selectedGifts: [],
    signature: { completed: false, type: "", data: "" }
  },
  mutations: {
    INIT_OPPORTUNITY(state, payload) {
      state.opportunity = payload;
      state.opportunityOriginal = JSON.parse(JSON.stringify(payload));
    },
    INIT_STRINGS(state, payload) {
      state.strings = payload;
    },
    INIT_PACKAGES(state, payload) {
      state.packages = payload;
    },
    INIT_ADDONS(state, payload) {
      state.addons = payload;
    },
    INIT_LINKS(state, payload) {
      state.links = payload;
    },
    UPDATE_DEALER_INFO(state, payload) {
      state.opportunity.dealerInformation[payload.prop] = payload.value;
    },
    UPDATE_STATE(state, { prop, value }) {
      state[prop] = value;
    },
    UPDATE_OPPORTUNITY(state, payload) {
      state.opportunity[payload.prop] = payload.value;
    },
    UPDATE_ADDRESS(state, payload) {
      state.opportunity.address[payload.prop] = payload.value;
    },
    UPDATE_SELECTED_PACKAGE(state, payload) {
      state.selectedPackage = payload;
    },
    UPDATE_SELECTED_ADDON(state, payload) {
      state.selectedAddon = payload;
    },
    UPDATE_SELECTED_GIFTS(state, payload) {
      state.selectedGifts = payload;
    },
    UPDATE_ERROR(state, payload) {
      state.error = payload;
    },
    UPDATE_LOADING_STATUS(state, { prop, value }) {
      state.loaded[prop] = value;
    },
    UPDATE_SIGNATURE(state, payload) {
      state.signature = payload;
    },
    SHOW_INCOMPLETE_ITEMS_MODAL(state, { messages, title }) {
      state.modalIncompleteItemsSubview.messages = messages;
      state.modal.title = title;
      state.modal.subview = "incompleteItems";
      state.modal.show = true;
    },
    SHOW_ERROR_MODAL(state, { message, title }) {
      state.modalErrorMessageSubview.message = message;
      state.modal.title = title;
      state.modal.subview = "error";
      state.modal.show = true;
    },
    SHOW_SIGNATURE_MODAL(state) {
      state.modal.title = "Sign";
      state.modal.subview = "signature";
      state.modal.show = true;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    SHOW_MODAL(state) {
      state.modal.show = true;
    },
    SHOW_LOADING_SPLASH(state) {
      state.showLoadingSplash = true;
    },
    HIDE_MODAL(state) {
      state.modal.show = false;
    },
    HIDE_LOADING_SPLASH(state) {
      state.showLoadingSplash = false;
    }
  },
  actions: {
    loadInitialState({commit }) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      const opportunityId = params.oppid;
      if(!opportunityId) {
        commit("UPDATE_ERROR", { code: 400, message: "No opportunity ID supplied" });
        return;
      }

      if(params.sub)
        commit("UPDATE_STATE", { prop: "sub", value: params.sub });

      var query = isLocal() ? `http://localhost:7071/api/opportunity/${opportunityId}` : `/api/opportunity/${opportunityId}`;

      Axios.get(query)
      .then(response => {
        if(response.data.clientId == "00000000-0000-0000-0000-000000000000") {
          commit("UPDATE_ERROR", { code: 400, message: "No opportunity found for this opportunity ID." })
          return;
        }
        
        commit("INIT_OPPORTUNITY", response.data);
        commit("UPDATE_LOADING_STATUS", { prop: "opportunity", value: true });

        // Set agreement defaults to true, as requested =\
        commit("UPDATE_OPPORTUNITY", { prop: "tou_Accepted", value: true });
        commit("UPDATE_OPPORTUNITY", { prop: "billImr", value: true });
        commit("UPDATE_OPPORTUNITY", { prop: "authorizedSigner", value: true });
      })
      .catch(error => {
        commit("UPDATE_ERROR", { code: error.response.status, message: error.response.data})
      });
    },
    updateSelectedAddon({ state, commit }, { addon, gifts }) {
      commit("UPDATE_OPPORTUNITY", {prop: "declinedGifts", value: false });

      if(state.selectedAddon === addon) {
        commit("UPDATE_SELECTED_ADDON", "");
        commit("UPDATE_SELECTED_GIFTS", "");
      }
      else {
        commit("UPDATE_SELECTED_ADDON", addon);
        commit("UPDATE_SELECTED_GIFTS", gifts);
      }
    },
    updateOpportunity({commit}, payload) {
      commit("UPDATE_OPPORTUNITY", payload);
    },
    showModal({commit}) {
      commit("SHOW_MODAL");
    },
    hideModal({commit}) {
      commit("HIDE_MODAL");
    },
    showSignatureModal({commit}) {
      commit("SHOW_SIGNATURE_MODAL");
    },
    updateSignature({commit}, payload) {
      commit("UPDATE_SIGNATURE", payload);
    },
    sendFullStoryEvent({state}, { FS, eventName, eventPayload }) {
      try {
        if(!eventPayload) {
          FS.event(eventName);
        }
        else {
          FS.event(eventName, eventPayload);
        }
      }
      catch(error) {
        console.warn(error);
      }
    },
    async validateAndSubmitContract({state, getters, commit}) {
      var incompleteItems = [];

      // Validate dealership information in the form
      !getters.dealerInformation.dealerName?.trim() ? incompleteItems.push("Dealership Name field is empty") : null;
      !getters.dealerInformation.bacCode?.trim() ? incompleteItems.push("BAC field is empty") : null;
      !getters.opportunity.digitalAirstrikeRepresentative?.trim() ? incompleteItems.push("Digital Air Strike Representative field is empty") : null;

      // Validate dealer address info in the form
      !getters.address.street?.trim() ? incompleteItems.push("Dealer street address field is empty") : null;
      !getters.address.city?.trim() ? incompleteItems.push("Dealer city field is empty") : null;
      !getters.address.state?.trim() ? incompleteItems.push("Dealer state field is empty") : null;
      !getters.address.zip?.trim() ? incompleteItems.push("Dealer ZIP code field is empty") : null;

      // Package and addon checks
      !state.selectedPackage ? incompleteItems.push("No package selected") : null;

      if(state.selectedAddon && (state.selectedAddon == "Best" || state.selectedAddon == "Better")) {
        if (state.selectedGifts.filter(gift => gift?.id).length < 2) {
          incompleteItems.push("Please select both gift choices")
        }
      } 

      if(state.selectedAddon && (state.selectedAddon == "Good")) {
        if (state.selectedGifts.filter(gift => gift?.id).length < 1) {
          incompleteItems.push("Please select a gift")
        }
      } 

      (!state.opportunity.declinedGifts && !state.selectedAddon) ? incompleteItems.push("Please select an addon, or decline them") : null;

      // Required checkboxes
      !state.opportunity.tou_Accepted ? incompleteItems.push("Terms and Conditions not accepted") : null;
      !state.opportunity.authorizedSigner ? incompleteItems.push("Authorized signer has not been confirmed") : null;

      // User information validation
      !state.signature.completed ? incompleteItems.push("Please sign the form") : null;
      !state.opportunity.signaturePrintedName?.trim() ? incompleteItems.push("Please type your full name into the 'Printed Name' field") : null;
      !state.opportunity.signatoryTitle?.trim() ? incompleteItems.push("Please add your job title") : null;

      if(incompleteItems.length > 0) {
        commit("SHOW_INCOMPLETE_ITEMS_MODAL", { title: "Whoops!", messages: incompleteItems });
        return;
      }

      commit("SHOW_LOADING_SPLASH");

      const finalizedOpportunity = JSON.parse(JSON.stringify(state.opportunity));

      let ipResponse = null;
      try {
        ipResponse = await (await Axios.get(`https://api.ipify.org?format=json`)).data;
        finalizedOpportunity.ipAddress = ipResponse.ip;
      }
      catch(error) {
        console.warn(error);
        commit("SHOW_ERROR_MODAL", { title: "Error", message: error });
        commit("HIDE_LOADING_SPLASH");
        return;
      }

      finalizedOpportunity.newPackageId = state.selectedPackage;
      finalizedOpportunity.newPackageName = state.selectedPackage == "1e696c9d-1fab-4245-9372-168fc4583eaf" 
        ? "GM Sell 2023" 
        : state.selectedPackage == "53106d28-ed57-469a-a015-8778f208b5cd" ? "GM Engage 2023" 
        : state.selectedPackage == "b86234ab-75be-4b23-8f71-8460e4743304" ? "GM Respond 2023" : "";

      finalizedOpportunity.sub = state.sub;
      finalizedOpportunity.addOn = state.selectedAddon;

      finalizedOpportunity.gifts = state.selectedGifts;

      finalizedOpportunity.signature = state.signature;

      if(isLocalOrDev()) {
        finalizedOpportunity.dealerInformation.contactEmail = "12d8533f-e538-4a7c-9d15-4db2c541a967@a1c1c603-86dc-4e72-8951-79a92b1db638.com";
      }

      var apiEndpoint = isLocal()
      ? "http://localhost:7071/api/opportunity"
      : "/api/opportunity";

      Axios.post(apiEndpoint, finalizedOpportunity, { timeout: 30000 })
        .then(response => {
          commit("UPDATE_OPPORTUNITY", { prop: "contractSigned", value: true })
          commit("UPDATE_OPPORTUNITY", { prop: "pdfUrl", value: response.data.contractUrl })
        })
        .catch(error => {
          console.warn(error);
          commit("SHOW_ERROR_MODAL", { title: "Error", message: error });
        })
        .then(() => {
          commit("HIDE_LOADING_SPLASH");
        });
    },
  },
  getters: {
    appLoading: state => { return (!state.loaded.opportunity) },
    opportunity: state => state.opportunity,
    dealerInformation: state => state.opportunity.dealerInformation,
    address: state => state.opportunity.address,
    strings: state => name => state.strings.filter(string => string.name === name)[0]?.value ?? "",
    stringObject: state => name => state.strings.filter(string => string.name === name)[0],
    link: state => linkName => state.links[linkName],
    currentPackage: state => state.opportunity.packageName.toUpperCase(),
    currentPackageSimple: state => state.opportunity.newPackageName.includes("Sell") ? "Sell" : state.opportunity.newPackageName.includes("Engage") ? "Engage" : "Respond",
    signatureModalVisible: state => (state.modal.subview == "signature" && state.modal.show == true)
  },
  modules: {

  }
})
