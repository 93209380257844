<template>
  <div class="hiw-card-root">
      <div class="header-container">
          <img class="number-image" :src="imageURL" alt="Number"><span>STEP {{ number }}</span>
      </div>
      <div class="content-container">
          <div class="body" v-html="content.body"></div>
          <div class="footer" v-html="content.footer"></div>
      </div>
  </div>
</template>

<script>
export default {
    name: "HowItWorksSectionCard",
    props: {
        number: {
            type: String,
            default: '1',
            required: true
        },
        content: {
            type: Object,
            required: true
        }
    },
    computed: {
        imageURL() {
            var images = require.context('@/assets/images/')
            return images('./' + this.number + ".png")
        }
    },
    components: {

    }
}
</script>

<style lang="scss" scoped>
.hiw-card-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    height: 100%;
    min-height: 250px;
    background: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 15px;
    box-sizing: border-box;
    cursor: pointer;
}

.header-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    column-gap: 10px;
    margin-bottom: 15px;
}

.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-weight: 500;
}

.footer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    text-align: center;
    margin-top: 20px;
}

.content-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    flex-grow: 1;
}

.number-image {
    height: 40px;
    width: 40px;
}

@media (min-width: $media-min-width) {
    .header-container {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 15px;
    }

    .hiw-card-root {
        min-height: 275px;
        width: 100%;
        max-width: 250px;
    }
}
</style>