<template>
  <div class="thank-you-page-root">
    <div class="outer-container">
      <img class="header-image" src="@/assets/images/das-logo-colored.png" alt="Thank You">
      <div style="display:flex; justify-content: center; align-items: center;">
        <img src="@/assets/images/imr-logo.png" alt="IMR">
        <img src="@/assets/images/dds-logo.png" alt="DDS">
      </div>

      <div class="main section">
        <div class="pyro">
          <div class="before"></div>
          <div class="after"></div>
        </div>

        <h1 class="main-heading">Thank you for partnering with Digital Air Strike.</h1>
        <div class="sub-text">Please download a copy of your GM SMRM agreement with Digital Air Strike for your records. It's all about the company you keep, and we are honored to partner with you to supercharge your Reputation Management initiatives.</div>
        <div class="button-container">
          <a :href="$store.state.opportunity.pdfUrl" download="" v-if="pdfUrlValid"><button class="download-button" :class="[ pdfUrlValid ? '' : 'disabled' ]">DOWNLOAD</button></a>
          <button class="download-button disabled" v-else>Call us</button>
        </div>
      </div>

      <div class="next-steps section">
        <div class="next-steps-heading">Next Steps...</div>
        <div class="point" data-num="1">To bill via IMR and get your entries to Richard Branson's Private Estate and the BIG GAME in February, you must go to the Dealer Portal by clicking the link <a href="https://www.autopartners.net/gmentsso/UI/Login?goto=https%3A%2F%2Fdealer.autopartners.net%3A443%2Fsites%2Fusdealerdigitalsolutionsapp%2FSitePages%2FProducts.aspx">HERE</a> and select Digital Air Strike as your partner from the Social Media Reputation Management enrollment site.
          <ul>
            <li>Make sure to select {{ $store.state.opportunity?.newPackageName ?? "your package" }}.</li>
            <li>Screenshot your submission and send it to your Client Advocate</li>
            <li>If you select DAS and your correct packages by the END OF NOVEMBER, you get to enter BOTH Richard Branson's Private Estate AND the BIG GAME! Send your Client Advocate a screenshot of your enrollment to LOCK IN your entries!</li>
          </ul>
        </div>
        <div class="point" data-num="2">Once your enrollment is received from the GM team, your dedicated Client Advocate will be reaching out to you to review your onboarding timeline for your new features and appropriate next steps to get you set up for success in 2023! </div>
      </div>

      <div class="thank-you-image section">
        <img src="@/assets/images/thank-you-photo.jpg" alt="DAS with Sir Richard Branson">
        <div class="image-subtext">25 of our top dealer partners, including several participants in the GM SMRM program, joined Sir Richard Branson in this amazing life-changing experience with one of the world's most successful entrepreneurs. Want to learn more about the trip? Check it out <a href="https://digitalairstrike.com/press/dealers-truly-get-away-from-it-all/">HERE</a>, as seen in Automotive News. </div>
      </div>

      <div class="footer section">
        <img src="@/assets/images/dealers-choice-award.png" alt="Dealer's Choice">
        <div class="footer-text">
          <div class="footer-header">Your Unfair Online Advantage™.</div>
          <div class="footer-subtext">Top dealers named Digital Air Strike the <span style="font-weight: bold;">Top Choice in 2022 for Reputation Management, Social Media Management, and Digital Marketing</span> because of the great results we deliver to over 7,000 dealers.</div>
          <a class="footer-link" href="http://dasgm2023.com" target="_blank">www.dasgm2023.com</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ThankYouPage',
  components: {
    
  },
  methods: {

  },
  computed: {
    pdfUrlValid() {
      return this.$store.state.opportunity.pdfUrl ? true : false;
    }
  },
  mounted() {
    window.scrollTo(0,0); // Because we're not reloading into a new page (just changing the view), we need to scroll to the top of the window to start at the top of the thank you page.
  }
}
</script>

<style lang="scss" scoped>
.thank-you-page-root {
  overflow: hidden;
  background-color: white;
}

.header-image {
  width: 90%;
  max-width: 538px;
  object-fit: cover;
  margin-top: 25px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:max-content;
  padding: 35px;
  box-sizing: border-box;
}

.main {
  position: relative;
  margin-top: 40px;
  background-color: #073980;
}

.main > .main-heading {
  width: 100%;
  color: white;
  font-size: clamp(38px, 5vw, 48px);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;
}

.main > .sub-text {
  width: 100%;
  color: #FFFFFF;
  font-size: clamp(20px, 3.5vw, 26px);
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

.main > .button-container {
  margin-top: 50px;
}

.download-button {
  border-radius: 4px;
  background-color: #0096DB;
  color: white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border: none;
}

.disabled {
  background-color: grey;
  opacity: 0.3;
}

.next-steps {
  row-gap: 30px;
  color: #4D4D4D;
}

.next-steps > .next-steps-heading {
  color: #DD6000;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 58px;
  text-align: center;
}

.next-steps > .point {
  width: 100%;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 32px;
}

.next-steps > .point::before {
  color: #DD6000;
  content: attr(data-num) ". ";
}

.thank-you-image {
  padding: 0px;
  background-color: #DD6000;
}

.thank-you-image > img {
  width: 100%;
  object-fit: cover;
}

.thank-you-image a:link,
.thank-you-image a:visited,
.thank-you-image a:hover,
.thank-you-image a:active {
  color: white;
}

.thank-you-image > .image-subtext {
  width: 85%;
  height: max-content;
  color: white;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer {
  color: #4D4D4D;
  padding-top: 10px;
  padding: 0px;
  margin-top: 20px;
  padding-bottom: 50px;
}

.footer > img {
  width: 90%;
  object-fit: cover;
  
}

.footer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  margin-top: 50px;
  text-align: center;
}

.footer-header {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
}

.footer-subtext {
  width: 85%;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
}

.footer-link {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  color: #0096DB;
  text-decoration: none;
}

@media (min-width: $media-min-width) {
  .section {
    padding: 70px;
  }

  .main > .main-heading {
    width: 80%;
  }

  .main > .sub-text {
    width: 85%;
  }

  .next-steps > .point {
    width: 85%;
  }

  .thank-you-image {
    padding: 0px;
  }

  .thank-you-image > .image-subtext {
    width: 85%;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .footer {
    padding-top: 0px;
  }
}

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
               random($width)-$width / 2 + px
               random($height)-$height / 1.2 + px
               hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff
}
@mixin keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-delay ($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
}

@mixin animation-duration ($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
}

@mixin animation ($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
}

@mixin transform ($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
}


.pyro {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none; 
}


.pyro > .before, .pyro > .after {
  $repetitions: 2;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation((1s bang ease-out $repetitions backwards, 1s gravity ease-in $repetitions backwards, 5s position linear $repetitions backwards));
}
    
.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}
        
@include keyframes(bang) {
  to {
    box-shadow:$box-shadow;
  }
}
    
@include keyframes(gravity)  {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}
    
@include keyframes(position) {
  0%, 19.9% {
    top: 10%;
    left: 80%;
  }
  20%, 39.9% {
    top: 40%;
    left: 30%;
  }
  40%, 59.9% {  
    top: 20%;
    left: 70%
  }
  60%, 79.9% {  
    top: 30%;
    left: 20%;
  }
  80%, 99.9% {  
    top: 30%;
    left: 80%;
  }
}

</style>