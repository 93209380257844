<template>
  <div class="addon-card-root" :style="{ '--accent-color': addon.accentColor, '--border-color': addon.borderColor , '--sub-accent-color': addon.subAccentColor}" :class="[selected ? 'selected' : '']">
      <div class="expanded" @click="select">
        <div class="section-1">
            <div class="title">{{ addon.name }}</div>
            <div class="label" :style="{ '--accent-color': addon.accentColor }">{{addon.label}}</div>
            <div class="best-flavor-text" v-if="addon.name.toUpperCase() == 'BEST'">If you purchase both the <b>Sell</b> package and the <b>Best package</b>, you get your choice of 2 SMRM package level gifts or lower, <b>PLUS</b> an iPad Air!</div>
            <div class="price" :style="{ '--price-margin-top': addon.name.toUpperCase() == 'BEST' ? '0px' : '50px' }"><span style="text-decoration: line-through; color:#B1B1B1;">${{addon.oldPrice}}</span> <span style="">${{addon.price}}</span>/mo</div>
            <div class="platform-fee"><span style="font-weight: 600;">${{addon.platformFee}}</span><br><div style="font-weight: 400;">One-Time Platform<br>Creation Fee</div></div>
            <div class="select-button" :class="[selected ? 'btn-selected' : 'btn-unselected' ]"><button>{{ selected ? "REMOVE" : "SELECT" }}</button></div>
        </div>

        <div class="section-2">
            <div style="font-weight: bold; color: #4D4D4D;">Package Features</div>
            <div class="features-container">
                <div class="addon-feature" v-for="(feature, index) in addon.features" :key="index"><i class="far fa-check addon-feature-check"></i>{{feature}}</div>
            </div>
        </div>

        <div class="section-3">
            <div style="width: 100%;">
                <div style="color: #4D4D4D; font-weight: bold; font-size: 18px; text-align: left;">Included gift</div>
            </div>

                <div class="image-container">
                    <img src="@/assets/images/ipad-air.png" alt="Best Prize" v-if="addon.id === 1">
                    <img src="@/assets/images/smrm_2023_gifts.jpg" alt="Best Prize" v-if="addon.id === 1">
                    <img src="@/assets/images/smrm_2023_gifts_2.jpg" alt="Better Prize" v-else-if="addon.id === 2">
                    <img src="@/assets/images/smrm_2023_gifts_2.jpg" alt="Good Prize" v-else-if="addon.id === 3">
                </div>

            <div style="font-size: 22px; line-height: 25px; color: #4D4D4D; font-weight: bold;">
                <div style="text-align: center;" v-if="addon.id === 1">{{ addon.prizeLabel }}</div>
                <div style="text-align: center;" v-if="addon.id === 2">{{ addon.prizeLabel }}</div>
                <div style="text-align: center;" v-if="addon.id === 3">{{ addon.prizeLabel }}</div>
            </div>

            <div v-if="addon.id === 1" style="display: flex; flex-direction: column; justify-content: center; align-items: center; row-gap: 5px;">
                    <span>
                        Selection 1:&nbsp;<select v-model="tier1Gift1" @click.stop :disabled="!selected" style="">
                            <option value="" hidden disabled>Select Gift</option>
                            <option v-for="prize in addonOptionalPrizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                        </select>
                    </span>

                    <span>
                        Selection 2:&nbsp;<select v-model="tier1Gift2" @click.stop :disabled="!selected">
                            <option value="" hidden disabled>Select Gift</option>
                            <option v-for="prize in addonOptionalPrizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                        </select>
                    </span>
            </div>

            <div v-if="addon.id === 2" style="display: flex; flex-direction: column; justify-content: center; align-items: center; row-gap: 5px;">
                    <span>
                        Selection 1:&nbsp;<select v-model="tier2Gift1" @click.stop :disabled="!selected">
                            <option value="" hidden disabled>Select Gift</option>
                            <option v-for="prize in addonOptionalPrizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                        </select>
                    </span>

                    <span>
                        Selection 2:&nbsp;<select v-model="tier2Gift2" @click.stop :disabled="!selected">
                            <option value="" hidden disabled>Select Gift</option>
                            <option v-for="prize in addonOptionalPrizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                        </select>
                    </span>
            </div>

            <div v-if="addon.id === 3" style="display: flex; justify-content: center; align-items: center;">
                    Selection:&nbsp;<select v-model="tier3Gift" @click.stop :disabled="!selected">
                        <option value="" hidden disabled>Select Gift</option>
                        <option v-for="prize in addon.prizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                    </select>
            </div>
        </div>
      </div>

      <div class="truncated" @click="select">
          <div class="section-1">
              <div class="title">{{ addon.name }}</div>

              <div class="label" :style="{ '--accent-color': addon.accentColor }">{{addon.label}}</div>

              <div class="price"><span style="text-decoration: line-through; color:#B1B1B1;">${{addon.oldPrice}}</span> <span style="font-weight: 500;">${{addon.price}}</span>/mo</div>

              <div class="platform-fee"><span style="font-weight: 500;">${{addon.platformFee}}</span> One-Time Platform Creation Fee</div>

              <div style="font-weight: bold;">Package Features</div>
              <div class="features-container">
                <div class="addon-feature" v-for="(feature, index) in addon.features" :key="index"><img src="@/assets/images/small-green-checkmark.svg" alt="">{{feature}}</div>
              </div>

                <div style="font-weight: bold; font-size: 20px;">Included gift:</div>

                <div class="image-container">
                    <img src="@/assets/images/ipad-air.png" alt="Best Prize" v-if="addon.id === 1">
                    <img src="@/assets/images/smrm_2023_gifts.jpg" alt="Best Prize" v-if="addon.id === 1">
                    <img src="@/assets/images/smrm_2023_gifts_2.jpg" alt="Better Prize" v-else-if="addon.id === 2">
                    <img src="@/assets/images/smrm_2023_gifts_2.jpg" alt="Good Prize" v-else-if="addon.id === 3">
                </div>

                <div style="" v-if="addon.id === 1">{{ addon.prizeLabel }}</div>
                <div style="" v-if="addon.id === 2">{{ addon.prizeLabel }}</div>
                <div style="" v-if="addon.id === 3">{{ addon.prizeLabel }}</div>

                <div v-if="addon.id === 1" style="display: flex; flex-direction: column; justify-content: center; align-items: center; row-gap: 5px;">
                    <span>
                        Selection 1:&nbsp;<select v-model="tier1Gift1" @click.stop :disabled="!selected" style="">
                            <option value="" hidden disabled>Select Gift</option>
                            <option v-for="prize in addonOptionalPrizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                        </select>
                    </span>

                    <span>
                        Selection 2:&nbsp;<select v-model="tier1Gift2" @click.stop :disabled="!selected">
                            <option value="" hidden disabled>Select Gift</option>
                            <option v-for="prize in addonOptionalPrizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                        </select>
                    </span>
            </div>

            <div v-if="addon.id === 2" style="display: flex; flex-direction: column; justify-content: center; align-items: center; row-gap: 5px;">
                    <span>
                        Selection 1:&nbsp;<select v-model="tier2Gift1" @click.stop :disabled="!selected">
                            <option value="" hidden disabled>Select Gift</option>
                            <option v-for="prize in addonOptionalPrizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                        </select>
                    </span>

                    <span>
                        Selection 2:&nbsp;<select v-model="tier2Gift2" @click.stop :disabled="!selected">
                            <option value="" hidden disabled>Select Gift</option>
                            <option v-for="prize in addonOptionalPrizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                        </select>
                    </span>
            </div>

            <div v-if="addon.id === 3" style="display: flex; justify-content: center; align-items: center;">
                    Selection:&nbsp;<select v-model="tier3Gift" @click.stop :disabled="!selected">
                        <option value="" hidden disabled>Select Gift</option>
                        <option v-for="prize in addon.prizes" :key="prize.id" :value="prize">{{ prize.name }}</option>
                    </select>
            </div>
              

              <div style="font-size: 10px; color: grey;" v-if="!selected">Tap to add!</div>
              <div style="font-size: 10px; color: grey;" v-else>Tap to remove</div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        addon: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            tier1Gift1Data: "",
            tier1Gift2Data: "",
            tier2Gift1Data: "",
            tier2Gift2Data: "",
            tier3GiftData: "",
            selectedGifts: []
        }
    },
    computed: {
        tier1Gift1: {
            get() {
                return this.tier1Gift1Data;
            },
            set(value) {
                this.tier1Gift1Data = value;
                this.selectedGifts = [this.tier1Gift1Data, this.tier1Gift2Data];
                this.$store.commit("UPDATE_SELECTED_GIFTS", this.selectedGifts);
            }
        },
        tier1Gift2: {
            get() {
                return this.tier1Gift2Data;
            },
            set(value) {
                this.tier1Gift2Data = value;
                this.selectedGifts = [this.tier1Gift1Data, this.tier1Gift2Data];
                this.$store.commit("UPDATE_SELECTED_GIFTS", this.selectedGifts);
            }
        },
        tier2Gift1: {
            get() {
                return this.tier2Gift1Data;
            },
            set(value) {
                this.tier2Gift1Data = value;
                this.selectedGifts = [this.tier2Gift1Data, this.tier2Gift2Data];
                this.$store.commit("UPDATE_SELECTED_GIFTS", this.selectedGifts);
            }
        },
        tier2Gift2: {
            get() {
                return this.tier2Gift2Data;
            },
            set(value) {
                this.tier2Gift2Data = value;
                this.selectedGifts = [this.tier2Gift1Data, this.tier2Gift2Data];
                this.$store.commit("UPDATE_SELECTED_GIFTS", this.selectedGifts);
            }
        },
        tier3Gift: {
            get() {
                return this.tier3GiftData;
            },
            set(value) {
                this.tier3GiftData = value;
                this.selectedGifts = [this.tier3GiftData];
                this.$store.commit("UPDATE_SELECTED_GIFTS", this.selectedGifts);
            }
        },
        selected() {
            return this.$store.state.selectedAddon === this.addon.name;
        },
        addonOptionalPrizes() {
            return this.addon.prizes.filter(prize => !prize.alwaysIncluded);
        }
    },
    methods: {
        select() {
            if(this.addon.id === 1)
                this.selectedGifts = [this.tier1Gift1Data, this.tier1Gift2Data];
            else if(this.addon.id === 2)
                this.selectedGifts = [this.tier2Gift1Data, this.tier2Gift2Data];
            else if(this.addon.id === 3)
                this.selectedGifts = [this.tier3GiftData];

            this.$store.dispatch("sendFullStoryEvent", { 
                FS: this.$FullStory,
                eventName: "SMRM-AddonSelected",
                eventPayload: {
                    previousAddon: this.$store.state.selectedAddon,
                    newAddon: this.addon.name,
                    gift: this.selectedGifts
                }
            });

            this.$store.dispatch('updateSelectedAddon', { addon: this.addon.name, gifts: this.selectedGifts });
        }
    }
}
</script>

<style lang="scss" scoped>
.addon-card-root {
    background: white;
    border-radius: 15px;
    border: solid #E1E1E1 1px;
    width: 100%;
    transition: all 0.15s ease;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: content-box;
    border: 3px solid transparent;
}

.selected {
    transform: scale(1.03);
    border: 3px solid var(--border-color);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.expanded {
    display: none;
}

.truncated {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 20px;
}

.section-1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    user-select: none;
}

.title {
    color: #4D4D4D;
    font-size: 35px;
    font-weight: bold;
}

.label {
    font-size: 14px;
    color: var(--accent-color);
    font-weight: 600;
}

.price {
    font-size: 15px;
    font-weight: 600;
}

.platform-fee {
    font-size: 12px;
    font-weight: 600;
}

.features-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
    row-gap: 15px;
    user-select: none;
}

.addon-feature {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 13px;
}

.addon-feature-check {
    color: var(--accent-color);
}

.section-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    user-select: none;
}

.section-3 {
    display: flex;
    flex-direction: column;
    flex: 2;
    user-select: none;
}

.image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container > img {
    max-width: 250px;
    max-height: 200px;
    object-fit: contain;
    flex: 1;
    flex-basis: 1;
}

select {
    box-sizing: border-box;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    padding: 10px;
}

@media (min-width: $media-min-width) {
    .addon-card-root {
        padding: 0px;
        padding-bottom: 0px;
        min-height: 350px;
    }

    .price {
        margin-top: var(--price-margin-top);
    }

    .title {
        font-size: 50px;
    }
    
    .expanded {
        display: flex;
        column-gap: 24px;
        padding: 20px;
        padding-bottom: 20px;
        padding-left: 40px;
        padding-right: 24px;
        max-height: 100%;
        min-height: 350px;
    }

    .truncated {
        display: none;
    }

    .image-container {
        position: relative;
    }

    .best-flavor-text {
        background-image: url("~@/assets/images/callout.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        padding: 20px;
        padding-left: 30px;
        padding-right: 30px;
        font-weight: 400;
        color: white;
        box-sizing: border-box;
        font-size: 16px;
        transform: translateX(-43px);
    }

    .better-flavor-text {
        background-image: url("~@/assets/images/preferred-flavor.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 150px;
    }

    .section-1 {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 15px;
    }

    .select-button {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        margin-bottom: 25px;
    }

    .btn-selected > button{
        color: #939495;
        border: 1px solid #939495;
        background: #f1f1f1;
    }

    .btn-unselected > button {
        color: var(--accent-color);
        border: 1px solid var(--accent-color);
        background-color: var(--sub-accent-color);
    }

    .select-button > button {
        padding: 12px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: bold;
    }

    .section-2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        flex: 1;
    }

    .section-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 20px;
        flex: 1;
    }
}

@media (min-width: 768px) and (max-width: 964px) {

}
</style>