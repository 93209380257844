import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import * as FullStory from '@fullstory/browser';
import { polyfill } from "seamless-scroll-polyfill";


polyfill();
document.title = 'SMRM';

const useDev = (window.location.host.includes("localhost") || window.location.host.includes("ambitious-tree-0e8fa8110"))

// Init FullStory as per their documentation: https://www.npmjs.com/package/@fullstory/browser
FullStory.init({ orgId: '13E4CE', devMode: useDev, debug: false});

const app = createApp(App).use(store);
app.config.globalProperties.$FullStory = FullStory;
app.mount('#app');
