<template>
  <div class="step-1-section-root" id="step-1-scroll-anchor">
      <div class="header">
          <div class="header-sub-left">
                  <div class="label">Step One:</div>
                  <div class="sub-label">Lead the market with Digital Air Strike's exclusive Social Media and Reputation Management solutions for GM SMRM Dealers!</div>
                  <div style="color: white; font-size: 11px;">Select the best package for your dealership!</div>
              </div>
            <div class="header-sub-right">1</div>
      </div>

        <div class="cards">
              <PackageSectionCard class="sell" :cardPackage="packages.sell" />
              <PackageSectionCard class="engage" :cardPackage="packages.engage" v-if="currentPackageIncludes('ENGAGE') || currentPackageIncludes('RESPOND')" />
              <PackageSectionCard class="respond" :cardPackage="packages.respond" v-if="currentPackageIncludes('RESPOND')"/>
          </div>
  </div>
</template>

<script>
import PackageSectionCard from '@/components/PackageSectionCard.vue'

export default {
    components: {
        PackageSectionCard
    },
    computed: {
        currentPackage() {
            return this.$store.state.opportunity.packageName.toUpperCase();
        },
        packages() {
            return this.$store.state.packages;
        }
    },
    methods: {
        currentPackageIncludes(pkg) {
            return this.currentPackage.includes(pkg);
        }
    }
}
</script>

<style lang="scss" scoped>
.step-1-section-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    padding-bottom: 35px;
}

.header {
    display: flex;
    margin-top: 20px;
}

.header-sub-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    row-gap: 10px;
}

.header-sub-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    color: white;
    text-align: right;
    font-size: 150px;
    opacity: 0.5;
    font-weight: bold;
}

.label {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.sub-label {
    color: #FFF;
    font-weight: bold;
}

// .content {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
// }

.cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    row-gap: 20px;
    margin-top: 25px;
}

.cards > div {
    flex: 1;
}

@media (min-width: $media-min-width) {
    .step-1-section-root {
        padding-bottom: 0px;
    }

    .cards {
        max-height: 700px;
        flex-direction: row;
        column-gap: 20px;
        align-items: center;
        justify-content: space-around;
        padding-top: 70px;
        padding-bottom: 70px;
        box-sizing: border-box;
        margin-top: 0px;
        transform: translateY(-70px);
    }

    .cards > div:nth-child(3) {
        order: 1;
    }

    .cards > div:nth-child(1) {
        order: 2;
    }

    .cards > div:nth-child(2) {
        order: 3;
    }    
    

    .label {
        font-size: 42px
    }

    .sub-label {
        margin-top: 25px;
    }

    .header-sub-right {
        font-size: 200px;
    }
}
</style>