<template>
  <div class="step-3-section-root" id="step-3-scroll-anchor">
    <div class="content">
        <p>
          <input type="checkbox" id="decline" v-model="declineGifts" />
          <label for="decline" style="color: white; font-size: 12px;">I decline the Lead Response and Engagement solutions, and by opting out understand that I am forfeiting on behalf of the dealership up to $21,000 in savings to help us sell more in 2023</label>
        </p>
      </div>
      <div class="header">
          <div class="header-sub-left">
                  <div class="label">Step Three:</div>
                  <div class="sub-label">Sign below and check the boxes to complete your GM SMRM enrollment with DAS and receive your gifts!  Note there is an additional step that needs to be done in GM Global Connect to complete your enrollment.</div>
                  <div style="color: white; font-style: italic; font-size: 11px;">Gifts are guaranteed to arrive before 12/25 if you sign by 11/23!</div>
              </div>
            <div class="header-sub-right">3</div>
      </div>
  </div>
</template>

<script>
export default {
  computed: {
    declineGifts: {
      get() {
        return this.$store.state.opportunity.declinedGifts;
      },
      set(value) {
        this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-OffersDeclined", eventPayload: { enabled: value } });
        this.$store.commit("UPDATE_OPPORTUNITY", { prop: "declinedGifts", value: value });

        if(value == true) {
          this.$store.commit("UPDATE_SELECTED_GIFTS", null);
          this.$store.commit("UPDATE_SELECTED_ADDON", null);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.step-3-section-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    padding-bottom: 35px;
}

.header {
    display: flex;
    margin-top: 20px;
    column-gap: 10px;
}

.header-sub-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 6;
    row-gap: 10px;
}

.header-sub-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-grow: 1;
    color: white;
    text-align: right;
    font-size: 150px;
    opacity: 0.5;
    font-weight: bold;
}

.label {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.sub-label {
    color: #FFF;
    font-weight: bold;
}

.content {
  display: flex;
  flex-direction: column;
}

.step-3-checkbox {
  width: 20px;
}

@media (min-width: $media-min-width) {
    .label {
        font-size: 42px
    }

    .header {
      margin-top: 0px;
    }

    .sub-label {
        font-size: 24px;
    }

    .header-sub-right {
      font-size: 200px;
    }

    .content { 
      align-items: flex-end;
      transform: translateY(-50px);
    }
}
</style>